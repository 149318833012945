angular.module('app.controllers')
    .controller('CooperadoListController',
        ['$scope', 'modalService', '$http', '$window', 'grideService', 'appConfig',
            function ($scope, modalService, $http, $window, grideService, appConfig) {

                $(".btn-box-tool-polaris").trigger("click");

                //Definições Globais da Controller
                $scope.titleModal = 'Cooperado';
                $scope.headerModalDelete = 'Excluir Cooperado';
                $scope.titleModalDeleteConfirmation = 'Confirma a Exclusão do Cooperado?';

                // $scope.produtoCategorias = function ($id) {
                //     $window.location.href = appConfig.baseUrl + '/administrativo/produto-categoria' + '/' + $id;
                // };
                //
                // $scope.produtos = function ($id) {
                //     $window.location.href = appConfig.baseUrl + '/administrativo/produto' + '/' + $id;
                // };
                //
                // $scope.pedidos = function ($id) {
                //     $window.location.href = appConfig.baseUrl + '/administrativo/pedido' + '/' + $id;
                // };
                //
                // $scope.cardapio = function ($id) {
                //     $window.location.href = appConfig.baseUrl + '/administrativo/estabelecimento-produto' + '/' + $id;
                // };
                //
                // $scope.mesas = function ($id) {
                //     $window.location.href = appConfig.baseUrl + '/administrativo/mesa' + '/' + $id;
                // };
                //
                // $scope.usuarios = function ($id) {
                //     $window.location.href = appConfig.baseUrl + '/administrativo/estabelecimento-usuario' + '/' + $id;
                // };
                //
                // $scope.avaliacao = function ($id) {
                //     $window.location.href = appConfig.baseUrl + '/administrativo/avaliacao-pergunta' + '/' + $id;
                // };

                // var buttonAction = '<button tooltip-append-to-body="true" uib-tooltip="Categorias" tooltip-placement="bottom-left" class="btn btn-xs btn-info" ng-click="grid.appScope.produtoCategorias(row.entity.id); $event.stopPropagation();"><span class="glyphicon glyphicon-folder-open"></span></button>';
                // buttonAction += '<button tooltip-append-to-body="true" uib-tooltip="Produtos" tooltip-placement="bottom-left" class="btn btn-xs btn-info" ng-click="grid.appScope.produtos(row.entity.id); $event.stopPropagation();"><i class="fa fa-file-image-o .text-azul-c"></i></button>';
                // buttonAction += '<button tooltip-append-to-body="true" uib-tooltip="Cardápio" tooltip-placement="bottom-left" class="btn btn-xs btn-info" ng-click="grid.appScope.cardapio(row.entity.id); $event.stopPropagation();"><span class="glyphicon glyphicon-list-alt"></span></button>';
                // buttonAction += '<button tooltip-append-to-body="true" uib-tooltip="Mesas" tooltip-placement="bottom-left" class="btn btn-xs btn-info" ng-click="grid.appScope.mesas(row.entity.id); $event.stopPropagation();"><span class="glyphicon glyphicon-unchecked"></span></button>';
                // buttonAction += '<button tooltip-append-to-body="true" uib-tooltip="Usuários" tooltip-placement="bottom-left" class="btn btn-xs btn-info" ng-click="grid.appScope.usuarios(row.entity.id); $event.stopPropagation();"><span class="glyphicon glyphicon-user"></span></button>';
                // buttonAction += '<button tooltip-append-to-body="true" uib-tooltip="Pedidos" tooltip-placement="bottom-left" class="btn btn-xs btn-info" ng-click="grid.appScope.pedidos(row.entity.id); $event.stopPropagation();"><span class="glyphicon glyphicon-home"></span></button>';
                // buttonAction += '<button tooltip-append-to-body="true" uib-tooltip="Avaliação" tooltip-placement="bottom-left" class="btn btn-xs btn-info" ng-click="grid.appScope.avaliacao(row.entity.id); $event.stopPropagation();"><span class="glyphicon glyphicon glyphicon-star"></span></button>';

                var columnDefs = [
                    {field: 'id', displayName: '#', minWidth: 50, width: 50},
                    {field: 'nome', displayName: 'Nome', minWidth: 150, width: '*'},
                    {field: 'documento', displayName: 'Documento', minWidth: 150, width: '*'},
                    // {
                    //     name: 'Menu',
                    //     field: 'Menu',
                    //     displayName: 'Menu',
                    //     enableFiltering: false,
                    //     enableSorting: false,
                    //     width: 250,
                    //     cellTemplate: buttonAction
                    // }
                ];

                $scope.gridOptions = grideService.montaGrideOptions($scope, '/administrativo/cooperado/', columnDefs, '/administrativo/cooperado/lista/json', 15);

            }]);
