Polaris.form = {
    /**
     * @type String
     */
    "requiredClass": "inputError",
    /**
     * @param {object jQuery} input
     * @param {object jQuery} hidden
     * @param {object} data
     * @returns {undefined}
     */
    "setupAutocomplete": function (input, hidden, data) {
        input.autocomplete({
            "minLength": 0,
            "source": function (request, response) {
                var term = Core.accentStrip(request.term);
                var matcher = new RegExp($.ui.autocomplete.escapeRegex(term), "i");
                response($.grep(data, function (row) {
                    var label = row.label;
                    var test = matcher.test(label) || matcher.test(Core.normalize(label));
                    return test;
                }));
            },
            "focus": function (event, ui) {
                input.removeClass(Polaris.form.requiredClass);
                input.val(ui.item.label);
                hidden.val(ui.item.value);
                return false;
            },
            "select": function (event, ui) {
                input.removeClass(Polaris.form.requiredClass);
                input.val(ui.item.label);
                hidden.val(ui.item.value);
                return false;
            }
        }).blur(function () {
            var value = input.val();
            if (value === '') {
                hidden.val('');
            } else {
                var found = false;
                for (var i in data) {
                    if (value === data[i].label) {
                        found = true;
                    }
                }
                if (false === found) {
                    input.val('');
                    hidden.val('');
                    input.addClass(Polaris.form.requiredClass);
                }
            }
//        }).focus(function() {
        }).click(function () {
//            input.autocomplete('search', input.val());
            input.autocomplete('search', '');
        });
    },
    /**
     * @param {mixed} container
     * @returns {object jQuery}
     */
    "getInputsFromContainer": function (container) {
        container = (container) ? container : false;
        var inputs = $();

        if (!container) {
            inputs = $(':input');
        } else if (container.jquery) {
            if (container.is(':input')) {
                inputs = container;
            } else {
                inputs = container.find(':input');
            }
        } else {
            inputs = $(container + ' :input');
        }
        return inputs;
    },

    /**
     * @param {mixed} container
     * @returns {undefined}
     */
    "setupInputs": function (container) {

        Core.console.group('Setup inputs...', true);

        try {
            container = container || $("body");

            if (typeof (container) === "string") {
                container = $(container);
            }
            if (!isset(container.jquery)) {
                throw new Core.Exception("Core.form.setupInputs: passe um seletor válido ou um objeto jQuery.");
            }
            Core.console.debug(container, 'container');

            var inputs = this.getInputsFromContainer(container);

            inputs.each(function () {
                var input = $(this);

                var configDefaultDatepicker = {
                    language: 'pt-BR',
                    format: 'dd/mm/yyyy',
                    autoclose: true
                };


                var controlType = input.attr('controltype');
                if (isset(controlType)) {
                    var controlTypeSplited = controlType.split('-');
                    var controlTypeName = controlTypeSplited[0];
                    var controlTypeComplement = controlTypeSplited[1];

                    Core.console.debug(sprintf("controlType: %s", controlType), input.attr('id'), Core.console.type.INFO);

                    switch (controlTypeName) {


                        //Core controltypes
                        case 'uf':
                            input.bind('change', function () {
                                //se tiver um dependente não está em um subform
                                var dependent = $(input.attr('dependent'));
                                if (dependent.length == 0) {
                                    var msg = "Para integração de uf com cidade é obrigatório adicionar ";
                                    msg += "um elemento dependente (combo cidade) ao input: ";
                                    msg += input.attr('id');
                                    msg += " Veja exemplo em 'default/exemplo/formulario'";
                                    Core.console.debug(msg);
                                }
                                //se ainda não existir um dependente é por que não foi setado, não faz nada
                                if (dependent.length) {
                                    input.getMunicipioByUf();

                                    if (input.hasClass('required')) {
                                        dependent.addClass('required');
                                    }

                                    if (input.val() == '' && !input.hasClass('required')) {
                                        dependent.removeClass('required');
                                    }
                                }
                            });
                            break;
                        case 'cpf':
                            input.mask('999.999.999-99')
                                .data('validateFunction', Core.validate.cpf)
                                .css('text-align', 'center');
                            break;
                        case 'cpf_cnpj':

                            var options = {
                                onKeyPress: function (cpf, ev, el, op) {
                                    console.log(cpf);
                                    var masks = ['000.000.000-000', '00.000.000/0000-00'];
                                    input.mask((cpf.length > 14) ? masks[1] : masks[0], op);
                                }
                            };

                            console.log(input.length);
                            console.log(input.val().length);
                            console.log('ok');

                            input.val().length > 14 ? input.mask('00.000.000/0000-00', options).data('validateFunction', Core.validate.cnpj) : input.mask('000.000.000-00#', options).data('validateFunction', Core.validate.cpf);

                            input.css('text-align', 'center');

                            break;
                        case 'processo':
                            input.mask('9999/999999')
                                .css('text-align', 'center');
                            break;
                        case 'rg':
                            input.mask('99.999.999?-9')
                                .css('text-align', 'center');
                            break;
                        case 'pispasep':
                            input.mask("9.999.999.999-9")
                                .data('validateFunction', Core.validate.pispasep)
                                .css('text-align', 'center');
                            break;
                        case 'ctps':
                            input.mask('999999-9')
                                .css('text-align', 'center');
                            break;
                        case 'cnpj':
                            input.mask('99.999.999/9999-99')
                                .data('validateFunction', Core.validate.cnpj)
                                .css('text-align', 'center');
                            break;
                        case 'cep':
                            input.mask('99999-999')
                                .css('text-align', 'center');
                            break;
                        case 'hour':
                            //@todo: implementar validador
                            input.data('validateFunction', Core.validate.hour)
                                .mask("99:99")
                                .css('text-align', 'center');
                            break;
                        case 'phone':
                            input.mask("(99) 9999-9999?9")
                                .css('text-align', 'center')
                                .focusout(function (event) {
                                    var target, phone, element;
                                    target = (event.currentTarget) ? event.currentTarget : event.srcElement;
                                    phone = target.value.replace(/\D/g, '');
                                    element = $(target);
                                    element.unmask();
                                    if (phone.length > 10) {
                                        element.mask("(99) 99999-9999");
                                    } else {
                                        element.mask("(99) 9999-99999");
                                    }
                                });
                            break;
                        case 'mobilephone':
                            input.mask("(99) 9999-99999")
                                .css('text-align', 'center')
                                .focusout(function (event) {
                                    var target, phone, element;
                                    target = (event.currentTarget) ? event.currentTarget : event.srcElement;
                                    phone = target.value.replace(/\D/g, '');
                                    element = $(target);
                                    element.unmask();
                                    if (phone.length > 10) {
                                        element.mask("(99) 99999-9999");
                                    } else {
                                        element.mask("(99) 9999-99999");
                                    }
                                });
                            break;
                        case 'float': //has controlTypeComplement (integer with precision)
                            //@todo: implementar validador
                            /*if (input.attr('maxlength')) {
                             input.data('validateFunction', isMaxlengthValid);
                             }*/
                            input.maskMoney({decimal: ",", thousands: "."})
                                .css('text-align', 'right');
                            break;
                        case 'float_zero_allowed': //has controlTypeComplement (integer with precision)
                            Core.console.debug('has controlTypeComplement (integer with precision)', input.attr('id'), Core.console.type.WARN);
                            input.maskMoney({precision: parseInt(controlTypeComplement)})
                                .css('text-align', 'right');
                            break;
                        case 'money':
                            input.maskMoney({prefix: "R$ ", decimal: ",", thousands: "."})
                                .css('text-align', 'right');
                            break;
                        case 'moneydecimal5':
                            input.maskMoney({prefix: "R$ ", decimal: ",", thousands: ".", precision: 5})
                                .css('text-align', 'right');
                            break;
                        case 'percentual':
                            input.maskMoney({suffix: "%", decimal: ",", thousands: "."})
                                .css('text-align', 'right');
                            break;
                        case 'score':
                            input.maskMoney({prefix: "Pts ", decimal: ",", thousands: "."})
                                .css('text-align', 'right');
                            break;
                        case 'lat_lng':
                            input.maskMoney({
                                allowNegative: true,
                                precision: 6,
                                decimal: ".", thousands: ""
                            })
                                .css('text-align', 'right');
                            break;
                        case 'points':
                            input.maskMoney({decimal: ",", thousands: "."})
                                .css('text-align', 'right');
                            break;
                        case 'ano_complemento':
                            input.mask("9999/9")
                                .css('text-align', 'center');
                            break;
                        case 'integer':
                            input.data('validateFunction', Core.validate.integer)
                                .bind({
                                    "keypress": function (event) {
                                        return Core.form.checkIntegerFromEvent(event);
                                    }
                                });
                            break;
                        case 'integer_with_zero':
                            input.data('validateFunction', Core.validate.integer)
                                .bind({
                                    "keypress": function (event) {
                                        return Core.form.checkIntegerFromEvent(event);
                                    },
                                    "focus": function () {
                                        if (parseInt($(this).val(), 10) === 0) {
                                            $(this).val('');
                                        }
                                    },
                                    "change blur": function () {
                                        Core.form.forceZeroInBlankValue(input);
                                    }
                                });
                            Core.form.forceZeroInBlankValue(input);
                            break;
                        case 'day':
                            input.data('validateFunction', Core.validate.date)
                                .attr('maxlength', '2')
                                .bind({
                                    "keypress": function (event) {
                                        return Core.form.checkIntegerFromEvent(event);
                                    }
                                });
                            break;
                        case 'year':
                            var configDatePicker = {
                                format: " yyyy",
                                viewMode: "years",
                                minViewMode: "years",
                                autoclose: true
                            };
                            input.mask('9999')
                                .css('text-align', 'center')
                                .datepicker(configDatePicker);
                            break;
                        case 'month_year':
                            input.data('validateFunction', Core.validate.date)
                                .mask('99/9999')
                                .css('text-align', 'center');
                            break;
                        case 'date':

                            input//.data('validateFunction', Core.validate.date)
                                .mask('99/99/9999')
                                .css('text-align', 'center')
                                .datepicker(configDefaultDatepicker);
                            break;
                        case 'init_date':
                            input.data('validateFunction', Core.validate.isInitDateValid)
                                .mask('99/99/9999')
                                .css('text-align', 'center')
                                .datepicker(configDefaultDatepicker);
                            break;
                        case 'final_date':
                            input.data('validateFunction', Core.validate.isFinalDateValid)
                                .mask('99/99/9999')
                                .css('text-align', 'center')
                                .datepicker(configDefaultDatepicker);
                            break;
                        case 'date_before_today':
                            //@todo: implementar validador
                            input.data('validateFunction', isDateBeforeToday)
                                .mask('99/99/9999')
                                .css('text-align', 'center')
                                .datepicker($.extend({"maxDate": "-1d"}, configDefaultDatepicker));
                            break;
                        case 'date_before_tomorrow':
                            //@todo: implementar validador
                            input.data('validateFunction', isDateBeforeTomorrow)
                                .mask('99/99/9999')
                                .css('text-align', 'center')
                                .datepicker($.extend({"maxDate": "d"}, configDefaultDatepicker));
                            break;
                        case 'date_after_today':
                            //@todo: implementar validador
                            input.data('validateFunction', isDateAfterToday)
                                .mask('99/99/9999')
                                .css('text-align', 'center')
                                .datepicker($.extend({"minDate": "+1d"}, configDefaultDatepicker));
                            break;
                        case 'date_after_yesterday':
                            //@todo: implementar validador
                            input.data('validateFunction', isDateAfterYesterday)
                                .mask('99/99/9999')
                                .css('text-align', 'center')
                                .datepicker($.extend({"minDate": "d"}, configDefaultDatepicker));
                            break;

                        case 'timestamp':
                            input//.data('validateFunction', Core.validate.date)
                                .mask('99/99/9999 99:99')
                                .css('text-align', 'center');
                            break;
                        case 'email':
                            input.data('validateFunction', Core.validate.email)
                                .css('text-transform', 'lowercase')
                            input.data('validateFunction', Core.validate.email);
                            break;
                        case 'separated_words': //has controlTypeComplement (upper_case|lower_case)
                            Core.console.debug('has controlTypeComplement (upper_case|lower_case)', input.attr('id'), Core.console.type.WARN);
                            //@todo: implementar validador
                            input.data('validateFunction', isValorCompostoValid);
                            if (controlTypeComplement === 'upper_case') {
                                input.css({
                                    "text-transform": "uppercase"
                                })
                                    .blur(function () {
                                        input.val(input.val().toUpperCase());
                                    });
                            } else if (controlTypeComplement === 'lower_case') {
                                input.css({
                                    "text-transform": "lowercase"
                                })
                                    .blur(function () {
                                        input.val(input.val().toLowerCase());
                                    });
                            }
                            break;
                        case 'no_separated_words': //has controlTypeComplement (upper_case|lower_case)
                            Core.console.debug('has controlTypeComplement (upper_case|lower_case)', input.attr('id'), Core.console.type.WARN);
                            //@todo: implementar validador
                            input.data('validateFunction', isValorSimplesValid);

                            if (controlTypeComplement === 'upper_case') {
                                input.css({"text-transform": "uppercase"})
                                    .blur(function () {
                                        input.val(input.val().toUpperCase());
                                    });
                            } else if (controlTypeComplement === 'lower_case') {
                                input.css({"text-transform": "lowercase"})
                                    .blur(function () {
                                        input.val(input.val().toLowerCase());
                                    });
                            }
                            break;
                        case 'upper_case':
                            input.css({"text-transform": "uppercase"})
                                .blur(function () {
                                    input.val(input.val().toUpperCase());
                                });
                            break;
                        case 'lower_case':
                            input.css({"text-transform": "lowercase"})
                                .blur(function () {
                                    input.val(input.val().toLowerCase());
                                });
                            break;

                        case 'editor' :
                            input.css('width', '100%'); //height: 200px; font-size: 14px; line-height: 18px; border: 1px solid #dddddd; padding: 10px;');
                            input.css('height', '200px');
                            input.css('font-size', '14px');
                            input.css('line-height', '18px');
                            input.css('border', '1px solid #dddddd');
                            input.css('padding', '10px');
                            input.addClass('hasEditor');
                            input.attr('contenteditable', false);
                            input.wysihtml5({
                                locale: "pt-BR"
                                /*events: {
                                 load: function () {
                                 if (input.is(':disabled')) {
                                 input.data("wysihtml5").editor.composer.disable();
                                 }
                                 }
                                 }*/
                            });
                            var div = input.parent('div');
                            if (input.hasClass('required')) {
                                div.addClass('required');
                            }
                            Core.console.debug('div = ' + div.attr('class'));

                            break;

                        case 'captcha' :
                            input.addClass('required');
                            break;


                        default:
                            Core.console.debug('Control Type não definido!', input.attr('id'), Core.console.type.ERROR);
                            break;
                    }
                }


                if (input.is('input[type=hidden]')) {
                    Core.console.debug("input.is('input[type=hidden]')", input.attr('id'), Core.console.type.INFO);
                    input.data("defaultValue", input.val());
                }

                if (input.is(':checkbox')) {
                    /*Core.console.debug("input.is(':checkbox')", input.attr('id'), Core.console.type.INFO);

                     var disabledCheckbox = input.attr('disabled');
                     var titleCheckbox = input.attr('title');

                     input.button();

                     if (disabledCheckbox) {
                     input.button('disable');
                     }

                     input.prev('label').attr('title', titleCheckbox); */
                }

                if (input.is(':radio')) {
                    //@todo comentei provisoriamente para os radios do NPNE funcionarem
                    /*Core.console.debug("input.is(':radio')", input.attr('id'), Core.console.type.INFO);

                     var disabledRadio = input.attr('disabled');
                     var titleRadio = input.attr('title');

                     var radioContainer = input.parent();
                     radioContainer.buttonset().find("label:last").addClass("ui-corner-right");

                     if (disabledRadio) {
                     input.button('disable');
                     }

                     input.prev('label').attr('title', titleRadio);*/
                }


                if (input.is('textarea')) {
                    Core.console.debug("input.is('textarea') && input.attr('maxlength')", input.attr('id'), Core.console.type.INFO);
                    clearInterval(input.data('charCounterInterval'));

                    input.data('charCounterInterval', setInterval(function () {
                        Core.form.charCounter(input);
                    }, 1000));

                    input.bind({
                        "keyup": function () {
                            Core.form.textareaLimited(input);
                        },
                        "change": function () {
                            Core.form.textareaLimited(input);
                        },
                        "blur": function () {
                            Core.form.textareaLimited(input);
                        },
                        "focusout": function () {
                            Core.form.textareaLimited(input);
                        },
                        "focus": function () {
                            Core.form.textareaLimited(input);
                        }
                    });
                }

                if (input.is('select')) {
                    if (input.attr("readonly")) {
                        Core.console.debug("input.is('select') && input.attr('readonly')", input.attr('id'), Core.console.type.INFO);
                        input.attr("readonly", "readonly");
                    }
                    if (input.find('.inactiveOption').length > 0) {
                        Core.console.debug("input.is('select') && input.find('.inactiveOption')", input.attr('id'), Core.console.type.INFO);
//                        input.one( "focus", function() {
                        input.bind("focus", function () {
                            $(this).find(".inactiveOption").attr("disabled", "disabled");
                        });
                    }
                }

                var minlength = parseInt(input.attr('minlength'));
                if (minlength > 0) {
                    if (!isNaN(minlength)) {
                        Core.console.debug("input.attr('minlength')", input.attr('id'), Core.console.type.INFO);
                        input.data("validateFunction", hasMinimalValue);
                    }
                }
//@todo: isso não exite nesse sistema...
//                input.blur(input.baseValidate);
            });

        } catch (e) {
            Core.exceptionHandler(e);
        }
        Core.console.groupEnd();
    }
};