angular.module('app.controllers').controller('UserListController', ['$scope', '$http', '$window', 'grideService', 'appConfig',
    function($scope, $http, $window, grideService,  appConfig) {
        /**
         * [filterOptions autocomplete email]
         * @type {Object}
         */
        $scope.filterOptions = {};
        $scope.filterOptions.email = "";
        //$scope.emails = User.getListaEmailUsuario();
        //definições globais da controller
        $scope.titleModal = 'Usuário';
        $scope.headerModalDelete = 'Excluir Usuário';
        $scope.titleModalDeleteConfirmation = 'Confirma a exclusão do usuário?';

        /**
         * [inicio chamada da gride]
         * @type {gridOptions}
         */
        var columnDefs = [{
            field: 'name',
            displayName: 'Nome',
            minWidth: 150,
            width: '*',
            cellTooltip: function(row, col) {
                return 'Nome: ' + row.entity.name + ', E-mail: ' + row.entity.email ;
            }
        },{
            field: 'login',
            displayName: 'Login',
            minWidth: 150,
            width: '*',
            cellTooltip: function(row, col) {
                return 'Login: ' + row.entity.login ;
            }
        } ];
        //chamada grid factory
        $scope.gridOptions = grideService.montaGrideOptions($scope, '/seguranca/users/', columnDefs, '/seguranca/listaUsuario/', 15);
    }
]);